import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import isEqual from "react-fast-compare"
import { pick } from "lodash"

import { FontAwesomeIcon } from "@lib/fontAwesome"
import { initialState } from "@reducers/ui"

import Button from "react-bulma-components/lib/components/button"

const FilterButton = ({ index, onPress }) => {
  const search = useSelector(state => state.ui.filters.search)

  let applied
  let original

  const _isNotEqual = () => {
    switch (index) {
      // Tattoos
      case 0:
        applied = pick(search, [
          "style",
          // "type",
          // "priceMin",
          // "priceMax"
        ])
        original = pick(initialState.filters.search, [
          "style",
          // "type",
          // "priceMin",
          // "priceMax",
        ])
        return !isEqual(applied, original)
      // Artists
      case 1:
        applied = pick(search, ["style"])
        original = pick(initialState.filters.search, ["style"])
        return !isEqual(applied, original)
      // Studios
      case 2:
        applied = pick(search, ["style"])
        original = pick(initialState.filters.search, ["style"])
        return !isEqual(applied, original)
      default:
        return false
    }
  }

  return (
    <Button color={"black"} onClick={onPress}>
      {_isNotEqual() && (
        <FontAwesomeIcon
          color={"#fff804"}
          icon={"circle"}
          size={"sm"}
          style={{ marginRight: "0.5rem" }}
        />
      )}
      <FontAwesomeIcon color={"white"} icon={"sliders-h"} size={"1x"} />
    </Button>
  )
}

FilterButton.defaultProps = {
  index: 0,
}

FilterButton.propTypes = {
  index: PropTypes.number.isRequired,
  onPress: PropTypes.func,
}

export default FilterButton
