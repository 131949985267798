import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
// import { Index, Configure } from "react-instantsearch-dom"

import FilterSearchInput from "@components/FilterSearchInput"
import FilterForSale from "@components/FilterForSale"
import FilterStyles from "@components/FilterStyles"
// import FilterTags from "@components/FilterTags"
import FilterLocations from "@components/FilterLocations"
import FilterReset from "@components/FilterReset"
// import { INDEX_HASHTAGS, TAGS_PER_PAGE_SEARCH } from "@constants"
import { useTranslation } from "@hooks"

import { Field, Control } from "react-bulma-components/lib/components/form"
import Button from "react-bulma-components/lib/components/button"

import styles from "./styles.module.scss"

const Filter = ({
  refine,
  showForSale,
  showStyles,
  showTags,
  showLocations,
  searchInput,
  show,
  close,
}) => {
  const [t] = useTranslation()

  return (
    // react-bulma-components' Modal uses a portal and removes the content from the page
    <div
      className={classnames("has-background-white", "modal", styles.Modal, {
        "is-active": show,
      })}
    >
      <div
        role="presentation"
        className={classnames("has-background-white", "modal-background")}
        // Effectively react-bulma-components' `closeOnBlur`
        onClick={close}
      ></div>
      <div className={classnames("modal-content", styles.Modal__content)}>
        <FilterSearchInput searchInput={searchInput} />
        <div className={styles.Modal__filters}>
          {showForSale && <FilterForSale />}
          {showStyles && <FilterStyles />}
          {/* {showTags && (
            // This is resetting the Algolia cache!
            <Index indexName={INDEX_HASHTAGS}>
              <Configure hitsPerPage={TAGS_PER_PAGE_SEARCH} />
              <FilterTags onPressItem={refine} />
            </Index>
          )} */}
          {showLocations && <FilterLocations onPressItem={refine} />}
        </div>
        <Field
          className={classnames(styles.Modal__buttonContainer, "has-addons")}
        >
          <Control>
            <FilterReset />
          </Control>
          <Control className={"is-expanded"}>
            <Button
              color="primary"
              fullwidth
              size={"large"}
              onClick={close}
              className={"has-text-black"}
            >
              {t("components.Filter.button.text")}
            </Button>
          </Control>
        </Field>
      </div>
      {/* <button
        type="button"
        onClick={close}
        className="modal-close is-large"
        aria-label="close"
      /> */}
    </div>
  )
}

Filter.defaultProps = {
  showForSale: false,
  showStyles: true,
  showTags: true,
  showLocations: true,
}

Filter.propTypes = {
  showForSale: PropTypes.bool.isRequired,
  showStyles: PropTypes.bool.isRequired,
  showTags: PropTypes.bool.isRequired,
  showLocations: PropTypes.bool.isRequired,
  refine: PropTypes.func.isRequired,
  searchInput: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}

export default Filter
