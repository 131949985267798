import React from "react"
import { connectCurrentRefinements } from "react-instantsearch-dom"

import Button from "react-bulma-components/lib/components/button"

const ClearRefinements = ({ items, refine }) => {
  return (
    <div className="ais-ClearRefinements clear-refinement">
      <Button
        className="ais-ClearRefinements-button"
        onClick={() => refine(items)}
        disabled={!items.length}
      >
        Clear refinements
      </Button>
    </div>
  )
}

const WrappedClearRefinements = connectCurrentRefinements(ClearRefinements)

export default WrappedClearRefinements
