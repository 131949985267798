import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@lib/fontAwesome"
import { withGoogleMaps } from "react-instantsearch-dom-maps"

import Button from "react-bulma-components/lib/components/button"

const ScaleChanger = ({ google, googleMapsInstance }) => {
  const _zoomIn = () => {
    googleMapsInstance?.setZoom(googleMapsInstance.getZoom() + 1)
  }

  const _zoomOut = () => {
    googleMapsInstance?.setZoom(googleMapsInstance.getZoom() - 1)
  }

  return (
    <Button.Group hasAddons={true} className={"zoom"}>
      <Button className={"has-background-white"}>
        <FontAwesomeIcon
          color={"black"}
          icon={"plus"}
          size={"lg"}
          onClick={_zoomIn}
        />
      </Button>
      <Button className={"has-background-white"}>
        <FontAwesomeIcon
          color={"black"}
          icon={"minus"}
          size={"lg"}
          onClick={_zoomOut}
        />
      </Button>
    </Button.Group>
  )
}

ScaleChanger.propTypes = {
  google: PropTypes.object,
  googleMapsInstance: PropTypes.object,
}

const WrappedScaleChanger = withGoogleMaps(ScaleChanger)

export default WrappedScaleChanger
