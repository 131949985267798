import React from "react"
import { connectCurrentRefinements } from "react-instantsearch-dom"
import { useDispatch } from "react-redux"

import { useTranslation } from "@hooks"
import { initialState } from "@reducers/ui"
import { filtersSearchUpdate } from "@actions"
// import { updateQuery } from "@lib/history"

import Button from "react-bulma-components/lib/components/button"

const Reset = ({ items, refine }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const resetSearch = () => {
    // Clear redux
    dispatch(
      filtersSearchUpdate({
        search: initialState.filters.search,
      })
    )

    // Update algolia search
    refine(items)

    // Update query string in browser URL bar
    // updateQuery("")
  }

  return (
    <Button pull={"right"} size={"large"} onClick={resetSearch} borderless>
      {t("components.FilterReset.text")}
    </Button>
  )
}

const ConnectedReset = connectCurrentRefinements(Reset)

const FilterReset = () => <ConnectedReset clearsQuery />

export default FilterReset
