import React from "react"
import { connectMenu } from "react-instantsearch-dom"
import { useSelector, useDispatch } from "react-redux"

import { useTranslation } from "@hooks"
import { STYLES } from "@constants"
import { filtersSearchUpdate } from "@actions"
import { selectStyle } from "@selectors"

import Button from "react-bulma-components/lib/components/button"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Styles = ({ refine, currentRefinement }) => {
  const [t] = useTranslation()

  const dispatch = useDispatch()

  const styles = [t("components.Filter.all"), ...STYLES]

  const select = style => {
    let styleOption

    if (style === t("components.Filter.all")) {
      styleOption = ""
    } else {
      styleOption = style
    }

    dispatch(
      filtersSearchUpdate({
        search: {
          style,
        },
      })
    )

    return refine(styleOption)
  }

  return (
    <Content>
      <Heading subtitle size={5} className={"has-text-black"}>
        {t("components.Filter.styles.title")}
      </Heading>
      <Button.Group>
        {styles.map((style, i) => (
          <Button
            onClick={() => select(style)}
            key={i}
            renderAs="span"
            color={
              (style === t("components.Filter.all") && !currentRefinement) ||
              currentRefinement === style
                ? "dark"
                : undefined
            }
            rounded
          >
            {/* TODO: Improve how to pass this to Button */}
            <span className={"is-capitalized"}>{style}</span>
          </Button>
        ))}
      </Button.Group>
    </Content>
  )
}

const ConnectedStyles = connectMenu(Styles)

const FilterStyles = () => {
  const [t] = useTranslation()

  const { style } = useSelector(selectStyle)

  return (
    <ConnectedStyles
      attribute={"styles"}
      defaultRefinement={
        style === t("components.Filter.all") ? undefined : style
      }
    />
  )
}

export default FilterStyles
