import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties"
import isEqual from "react-fast-compare"

function getStateWithoutPage(state) {
  var _ref = state || {},
    // page = _ref.page,
    rest = _objectWithoutProperties(_ref, ["page"])

  return rest
}

function hasSessionStorage() {
  return (
    typeof window !== "undefined" &&
    typeof window.sessionStorage !== "undefined"
  )
}

export default function createInfiniteHitsSessionStorageCache(
  KEY = "ais.infiniteHits"
) {
  return {
    read: function read(_ref2) {
      var state = _ref2.state

      if (!hasSessionStorage()) {
        return null
      }

      try {
        var cache = JSON.parse(window.sessionStorage.getItem(KEY))
        return cache && isEqual(cache.state, getStateWithoutPage(state))
          ? cache.hits
          : null
      } catch (error) {
        if (error instanceof SyntaxError) {
          try {
            window.sessionStorage.removeItem(KEY)
          } catch (err) {
            // do nothing
          }
        }

        return null
      }
    },
    write: function write(_ref3) {
      var state = _ref3.state,
        hits = _ref3.hits

      if (!hasSessionStorage()) {
        return
      }

      try {
        window.sessionStorage.setItem(
          KEY,
          JSON.stringify({
            state: getStateWithoutPage(state),
            hits: hits,
          })
        )
      } catch (error) {
        // do nothing
      }
    },
  }
}
