import React, { useState, useRef } from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import PropTypes from "prop-types"
import classnames from "classnames"
import {
  GoogleMapsLoader,
  GeoSearch,
  Control as MapControl,
  CustomMarker,
} from "react-instantsearch-dom-maps"

import { FontAwesomeIcon } from "@lib/fontAwesome"
import { useCurrentLocation } from "@hooks"
import Filter from "@components/Filter"
import ScaleChanger from "@components/ScaleChanger"
import CenterMe from "@components/CenterMe"
import ClearRefinements from "@components/ClearRefinements"
import FilterSearch from "@components/FilterSearch"
// import HitCount from "@components/HitCount"
import FilterButton from "@components/FilterButton"
import mapStyle from "@assets/json/mapStyle.json"

import {
  Field,
  Control,
  Input,
} from "react-bulma-components/lib/components/form"
import Columns from "react-bulma-components/lib/components/columns"
import Hero from "react-bulma-components/lib/components/hero"
import Container from "react-bulma-components/lib/components/container"
import Button from "react-bulma-components/lib/components/button"
import Image from "react-bulma-components/lib/components/image"

import styles from "./styles.module.scss"

const Search = ({
  currentRefinement,
  refine,
  showForSale,
  showStyles,
  hasMap,
  setShowMap,
  showMap,
}) => {
  // Modal related
  const searchInput = useRef(null)

  const [show, setShow] = useState(false)
  const open = () => {
    setShow(true)
    setTimeout(() => {
      searchInput.current &&
        typeof searchInput.current.focus === "function" &&
        searchInput.current.focus()
    }, 200)
  }
  const close = () => setShow(false)

  // Map related
  const [highlightedMarker, setHighlightedMarker] = useState(null)
  const [currentLocation] = useCurrentLocation()

  return (
    <>
      <Hero
        className={classnames(styles.Search, "has-background", {
          "is-fullheight": showMap,
          "has-background-black": showMap,
        })}
      >
        {hasMap && (
          <>
            <GoogleMapsLoader
              apiKey={process.env.GATSBY_GOOGLE_API_KEY}
              endpoint={`https://maps.googleapis.com/maps/api/js?v=weekly`}
            >
              {google => {
                return (
                  <GeoSearch
                    google={google}
                    minZoom={4}
                    styles={mapStyle}
                    disableDefaultUI
                    // Prevent scroll and drag when small
                    // scrollwheel={showMap}
                    // draggable={showMap}
                    // enableRefineControl={false}
                    enableRefineOnMapMove={false} // Causes map to zoom out
                  >
                    {({ hits }) => (
                      <>
                        {currentLocation &&
                          currentLocation.latitude &&
                          currentLocation.longitude && (
                            <CustomMarker
                              key={"currentLocation"}
                              hit={{
                                _geoloc: {
                                  lat: currentLocation.latitude,
                                  lng: currentLocation.longitude,
                                },
                              }}
                            >
                              <FontAwesomeIcon
                                color={"yellow"}
                                icon={"map-marker-alt"}
                                size={"lg"}
                              />
                            </CustomMarker>
                          )}
                        {hits
                          .filter(
                            hit =>
                              typeof hit._geoloc === "object" &&
                              typeof hit._geoloc.lat === "number" &&
                              typeof hit._geoloc.lng === "number"
                          )
                          .map(hit => {
                            hit._geoloc = {
                              lat: parseFloat(hit._geoloc.lat),
                              lng: parseFloat(hit._geoloc.lng),
                            }
                            return hit
                          })
                          .map(hit => (
                            <CustomMarker key={hit.objectID} hit={hit}>
                              <Image
                                size={24}
                                alt={`Image of ${hit.username}`}
                                src={hit.photoUrl}
                                rounded
                                className={classnames(
                                  "is-square",
                                  "user-image",
                                  {
                                    "selected-marker":
                                      highlightedMarker === hit.objectID,
                                  }
                                )}
                                loading={"lazy"}
                              />
                            </CustomMarker>
                          ))}
                        {showMap && (
                          <>
                            <div className={"search-as-i-move"}>
                              <MapControl />
                              <ClearRefinements
                                transformItems={hits =>
                                  hits.filter(hits => hits.id === "boundingBox")
                                }
                                translations={{
                                  reset: "Clear",
                                }}
                              />
                            </div>
                            <ScaleChanger />
                            <CenterMe />
                          </>
                        )}
                      </>
                    )}
                  </GeoSearch>
                )
              }}
            </GoogleMapsLoader>
            <Button
              className={classnames(styles.Search__mapToggle, "is-floating")}
              onClick={() => setShowMap(!showMap)}
            >
              <FontAwesomeIcon
                icon={showMap ? "list" : ["far", "map"]}
                size={"xs"}
                color={"black"}
              />
            </Button>
          </>
        )}
        {!showMap && (
          <Hero.Body>
            <Container>
              <Columns breakpoint="mobile">
                <Columns.Column>
                  <FilterSearch />
                  <Field
                    className={classnames("has-addons", "has-addons-centered")}
                  >
                    <Control className={"is-expanded"}>
                      <Input
                        placeholder="Search"
                        value={currentRefinement}
                        onClick={open}
                      />
                    </Control>
                    <Control>
                      <FilterButton onPress={open} />
                    </Control>
                  </Field>
                  {/* <HitCount /> */}
                </Columns.Column>
              </Columns>
            </Container>
          </Hero.Body>
        )}
        <Filter
          showForSale={showForSale}
          showStyles={showStyles}
          // showTags={showTags}
          // showLocations={showLocations}
          refine={refine}
          searchInput={searchInput}
          show={show}
          close={close}
        />
      </Hero>
    </>
  )
}

Search.defaultProps = {
  showForSale: false,
  showStyles: true,
  hasMap: false,
  setShowMap: () => {},
  showMap: false,
}

Search.propTypes = {
  showForSale: PropTypes.bool.isRequired,
  showStyles: PropTypes.bool.isRequired,
  hasMap: PropTypes.bool.isRequired,
  setShowMap: PropTypes.func.isRequired,
  showMap: PropTypes.bool.isRequired,
}

export default connectSearchBox(Search)
