import React from "react"
import PropTypes from "prop-types"
import { withGoogleMaps } from "react-instantsearch-dom-maps"

import { FontAwesomeIcon } from "@lib/fontAwesome"
import { useCurrentLocation } from "@hooks"

import Button from "react-bulma-components/lib/components/button"

const CenterMe = ({ google, googleMapsInstance }) => {
  const [currentLocation] = useCurrentLocation()

  const _center = () => {
    if (
      currentLocation &&
      currentLocation.latitude &&
      currentLocation.longitude
    ) {
      const initialLocation = new google.maps.LatLng(
        currentLocation.latitude,
        currentLocation.longitude
      )
      googleMapsInstance?.setCenter(initialLocation)
      googleMapsInstance?.setZoom(15)
    }
  }

  return (
    <Button className={"center-me has-background-white"} onClick={_center}>
      <FontAwesomeIcon color={"black"} icon={"map-marker-alt"} size={"lg"} />
    </Button>
  )
}

CenterMe.propTypes = {
  google: PropTypes.object,
  googleMapsInstance: PropTypes.object,
}

const WrappedCenterMe = withGoogleMaps(CenterMe)

export default WrappedCenterMe
