import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import classnames from "classnames"
// import { globalHistory } from "@reach/router"

import { useTranslation } from "@hooks"
// import { updateQuery } from "@lib/history"

import {
  Field,
  Control,
  Input,
} from "react-bulma-components/lib/components/form"

import styles from "./styles.module.scss"

// https://theadhocracy.co.uk/wrote/adding-search-refining-frontend
const SearchBox = ({
  searchInput,
  currentRefinement,
  isSearchStalled,
  refine,
}) => {
  const [t] = useTranslation()

  const onChange = event => {
    // Update algolia search
    refine(event.currentTarget.value)
    // Update query string in browser URL bar
    // updateQuery(event.currentTarget.value)
  }

  return (
    <Field className={styles.FilerSearchInput}>
      <Control
        iconRight
        classname={classnames({
          "is-loading": isSearchStalled,
        })}
      >
        <Input
          domRef={searchInput}
          placeholder={t("components.Filter.search.placeholder")}
          value={currentRefinement}
          onChange={onChange}
          size={"large"}
        />
      </Control>
    </Field>
  )
}

const ConnectedSearchInput = connectSearchBox(SearchBox)

const FilterSearchInput = ({ searchInput }) => {
  // Do we need this?
  // let urlQuery = globalHistory.location.search
  //   ? globalHistory.location.search.replace("?query=", "")
  //   : ""
  return (
    <ConnectedSearchInput
      // defaultRefinement={urlQuery}
      searchInput={searchInput}
    />
  )
}

export default FilterSearchInput
