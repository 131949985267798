import React from "react"
import PropTypes from "prop-types"
import { connectRefinementList, Highlight } from "react-instantsearch-dom"
import classnames from "classnames"

import { useTranslation } from "@hooks"
import { FontAwesomeIcon } from "@lib/fontAwesome"
import NoResults from "@components/NoResults"

import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

import styles from "./styles.module.scss"

const FilterLocations = ({ items, query, refine, onPressItem }) => {
  const [t] = useTranslation()

  const regex = new RegExp(query)

  const hits = items
    .map(item => {
      return {
        count: item.count,
        fullyHighlighted: item.label === query,
        matchedWords: item.label.match(regex) || [],
        matchLevel: item.label.match(regex) || [].length ? "full" : undefined,
        value: item.label.replace(
          regex,
          "<ais-highlight-0000000000>$&</ais-highlight-0000000000>"
        ),
      }
    })
    .sort(a => {
      // Only sort if there is a query
      return query ? (a.matchLevel === "full" ? -1 : 1) : 1
    })
    .sort(a => {
      // Only sort if there is a query
      return query ? (a.fullyHighlighted ? -1 : 1) : 1
    })
    .map(item => {
      return {
        _highlightResult: {
          location: item,
        },
      }
    })

  const onClick = item => {
    if (
      item &&
      item._highlightResult &&
      item._highlightResult.location &&
      item._highlightResult.location.value
    ) {
      // TODO: Bit of a hack...
      // Remove <ais-highlight-0000000000>hi</ais-highlight-0000000000>
      // And insert into search bar
      const query = item._highlightResult.location.value.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      )
      // Update results
      refine(query)

      // Update search bar
      onPressItem(query)
    }
  }

  return (
    <Content>
      <Heading subtitle size={5} className={"has-text-black"}>
        {t("components.Filter.locations.title")}
      </Heading>
      <ul>
        {!hits.length ? (
          <li className={"has-background-white"} borderless>
            <NoResults />
          </li>
        ) : (
          hits.map((hit, i) => (
            <li
              key={i}
              className={"has-background-white"}
              onClick={() => onClick(hit)}
              borderless
            >
              <FontAwesomeIcon
                icon={"map-marker-alt"}
                color={"black"}
                className={styles.FilterLocations__icon}
              />
              <Highlight
                attribute={"location"}
                hit={hit}
                className={"has-text-black"}
              />
              <strong
                className={classnames("is-pulled-right", "has-text-black")}
              >
                {hit._highlightResult.location.count}
              </strong>
            </li>
          ))
        )}
      </ul>
    </Content>
  )
}

FilterLocations.propTypes = {
  items: PropTypes.array,
  query: PropTypes.string,
  refine: PropTypes.func.isRequired,
  onPressItem: PropTypes.func.isRequired,
}

const ConnectedLocations = connectRefinementList(FilterLocations)

const FilterLocationsComponent = ({ onPressItem }) => (
  <ConnectedLocations attribute={"location"} onPressItem={onPressItem} />
)

FilterLocationsComponent.propTypes = {
  onPressItem: PropTypes.func.isRequired,
}

export default FilterLocationsComponent
