import React from "react"
import { connectRefinementList } from "react-instantsearch-dom"
import { invert, isEqual } from "lodash"
import { useSelector, useDispatch } from "react-redux"

import { useTranslation } from "@hooks"
import { PHOTO_TATTOO, PHOTO_FLASH, PHOTO_COLLECTION } from "@constants"
import { filtersSearchUpdate } from "@actions"
import { selectType } from "@selectors"

import Button from "react-bulma-components/lib/components/button"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const ForSale = ({ refine, items }) => {
  const [t] = useTranslation()

  const dispatch = useDispatch()

  const types = [
    t("components.Filter.forSale.inputs.all"),
    t("components.Filter.forSale.inputs.flash"),
    t("components.Filter.forSale.inputs.tattoo"),
  ]

  const invertedTypes = invert(
    t(
      "components.Filter.forSale.inputs",
      {
        returnObjects: true,
      },
      {}
    )
  )

  const select = type => {
    const tattooType = invertedTypes[type]
    let newType

    if (
      !tattooType ||
      tattooType === t("components.Filter.forSale.inputs.all")
    ) {
      newType = [PHOTO_TATTOO, PHOTO_FLASH]
    } else if (tattooType === PHOTO_TATTOO) {
      newType = [PHOTO_TATTOO]
    } else if (tattooType === PHOTO_FLASH) {
      newType = [PHOTO_FLASH]
    }

    if (newType) {
      dispatch(
        filtersSearchUpdate({
          search: {
            type: newType,
          },
        })
      )

      return refine(newType)
    }
  }

  const selectRole = role => {
    let newRole

    if (role === t("components.Filter.forSale.inputs.community")) {
      newRole = [PHOTO_COLLECTION]
    } else {
      newRole = [PHOTO_TATTOO, PHOTO_FLASH]
    }

    dispatch(
      filtersSearchUpdate({
        search: {
          type: newRole,
        },
      })
    )

    return refine(newRole)
  }

  return (
    <Content>
      <Heading subtitle size={5} className={"has-text-black"}>
        {t("components.Filter.forSale.titles.uploadedBy")}
      </Heading>
      <Button.Group>
        <Button
          onClick={() =>
            selectRole(t("components.Filter.forSale.inputs.artists"))
          }
          renderAs="span"
          color={
            items.find(
              item => item.label === PHOTO_COLLECTION && item.isRefined
            )
              ? undefined
              : "dark"
          }
          rounded
        >
          {/* TODO: Improve how to pass this to Button */}
          <span className={"is-capitalized"}>
            {t("components.Filter.forSale.inputs.artists")}
          </span>
        </Button>
        <Button
          onClick={() =>
            selectRole(t("components.Filter.forSale.inputs.community"))
          }
          renderAs="span"
          color={
            items.find(
              item => item.label === PHOTO_COLLECTION && item.isRefined
            )
              ? "dark"
              : undefined
          }
          rounded
        >
          {/* TODO: Improve how to pass this to Button */}
          <span className={"is-capitalized"}>
            {t("components.Filter.forSale.inputs.community")}
          </span>
        </Button>
      </Button.Group>
      {items.find(
        item => item.label === PHOTO_COLLECTION && item.isRefined
      ) ? null : (
        <>
          <Heading subtitle size={5} className={"has-text-black"}>
            {t("components.Filter.forSale.titles.forSale")}
          </Heading>
          <Button.Group>
            {types.map((type, i) => (
              <Button
                onClick={() => select(type)}
                key={i}
                renderAs="span"
                color={
                  // A bit hacky...
                  // Select all when no values are selected
                  (type === t("components.Filter.forSale.inputs.all") &&
                    !items.find(item => item.isRefined)) ||
                  // Select all when PHOTO_TATTOO, PHOTO_FLASH are selected
                  (type === t("components.Filter.forSale.inputs.all") &&
                    isEqual(
                      items
                        .filter(item => item.isRefined)
                        .map(item => item.label)
                        .sort(),
                      [PHOTO_TATTOO, PHOTO_FLASH].sort()
                    )) ||
                  (items.find(
                    item => item.label === invertedTypes[type] && item.isRefined
                  ) &&
                    items.filter(item => item.isRefined).length === 1)
                    ? "dark"
                    : undefined
                }
                rounded
              >
                {/* TODO: Improve how to pass this to Button */}
                <span className={"is-capitalized"}>{type}</span>
              </Button>
            ))}
          </Button.Group>
        </>
      )}
    </Content>
  )
}

const ConnectedForSale = connectRefinementList(ForSale)

const FilterForSale = () => {
  const { type } = useSelector(selectType)
  return <ConnectedForSale attribute={"type"} defaultRefinement={type} />
}

export default FilterForSale
