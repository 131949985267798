import React from "react"
import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import classnames from "classnames"

import * as ROUTES from "@constants/routes"
import { useTranslation } from "@hooks"

import { Field, Control } from "react-bulma-components/lib/components/form"
import Button from "react-bulma-components/lib/components/button"

import styles from "./styles.module.scss"

const FilterSearch = () => {
  const [t] = useTranslation()

  const path = globalHistory.location.pathname.replace(/\/+$/, "")

  return (
    <Field className={classnames(styles.FilterSearch, "has-addons")}>
      <Control className={"is-expanded"}>
        <Button
          to={ROUTES.TATTOOS}
          renderAs={Link}
          color={path === ROUTES.TATTOOS ? "light" : undefined}
          className={classnames(styles.FilterSearch__button, "is-fullwidth")}
          borderless
        >
          {t("components.FilterSearch.tattoos")}
        </Button>
      </Control>
      <Control className={"is-expanded"}>
        <Button
          to={ROUTES.ARTISTS}
          renderAs={Link}
          color={path === ROUTES.ARTISTS ? "light" : undefined}
          className={classnames(styles.FilterSearch__button, "is-fullwidth")}
          borderless
        >
          {t("components.FilterSearch.artists")}
        </Button>
      </Control>
      <Control className={"is-expanded"}>
        <Button
          to={ROUTES.STUDIOS}
          renderAs={Link}
          color={path === ROUTES.STUDIOS ? "light" : undefined}
          className={classnames(styles.FilterSearch__button, "is-fullwidth")}
          borderless
        >
          {t("components.FilterSearch.studios")}
        </Button>
      </Control>
    </Field>
  )
}

export default FilterSearch
